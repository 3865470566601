export default class SupportCenter {
  constructor() {
    throw "This class contains only static methods!"
  }

  static init(settings) {
    window.intercomSettings = settings
    const appId = settings.app_id || "q1hly77x"
    const script = `(function () {var w = window;var ic = w.Intercom;if (typeof ic === "function") {ic('reattach_activator');ic('update', intercomSettings);} else {var d = document;var i = function () {i.c(arguments)};i.q = [];i.c = function (args) {i.q.push(args)};w.Intercom = i;function l () {var s = d.createElement('script');s.type = 'text/javascript';s.async = true;s.src = 'https://widget.intercom.io/widget/${appId}';var x = d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);}if (w.attachEvent) {w.attachEvent('onload', l);} else {w.addEventListener('load', l, false);}}})()`

    let javascriptElement = document.createElement("script")
    javascriptElement.text = script
    document.body.appendChild(javascriptElement)
  }

  static isAvailable() {
    return typeof window.Intercom !== typeof undefined
  }

  static enable() {
    if (SupportCenter.isAvailable()) {
      Intercom("boot", window.intercomSettings)
    }
  }

  static disable() {
    if (SupportCenter.isAvailable()) {
      Intercom("shutdown")
    }
  }

  static showNewMessage(content) {
    if (SupportCenter.isAvailable()) {
      Intercom("showNewMessage", content)
    }
  }

  static update() {
    if (SupportCenter.isAvailable()) {
      Intercom("update")
    }
  }
}
