export function getConditionalCallback(selector, callback, instance) {
  return function (e) {
    if (!e.target) {
      return
    }
    const target = e.target
    if (typeof target.matches !== "function" || !target.matches(selector)) {
      return
    }
    callback.apply(instance || this, arguments)
  }
}

export function findParent(el, selector) {
  let matchesFn
  ;[
    "matches",
    "webkitMatchesSelector",
    "mozMatchesSelector",
    "msMatchesSelector",
    "oMatchesSelector",
  ].some(function (fn) {
    if (typeof document.body[fn] == "function") {
      matchesFn = fn
      return true
    }
    return false
  })

  let parent

  while (el) {
    parent = el.parentElement
    if (parent && parent[matchesFn](selector)) {
      return parent
    }
    el = parent
  }

  return null
}
