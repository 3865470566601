import { getConditionalCallback } from "./utils"

export default class Inputs {
  static bind() {
    const instance = new Inputs()
    document.addEventListener("input", getConditionalCallback("input", instance.onInput, instance))
    document.addEventListener("change", getConditionalCallback("input", instance.onInput, instance))
    document.addEventListener("paste", getConditionalCallback("input", instance.onInput, instance))
    document.addEventListener("keyup", getConditionalCallback("input", instance.onInput, instance))

    document.addEventListener(
      "animationstart",
      (event) => instance.onInsert.call(instance, event),
      false
    ) // standard + firefox
    document.addEventListener(
      "MSAnimationStart",
      (event) => instance.onInsert.call(instance, event),
      false
    ) // IE
    document.addEventListener(
      "webkitAnimationStart",
      (event) => instance.onInsert.call(instance, event),
      false
    ) // Chrome + Safari

    Array.from(document.querySelectorAll("input")).forEach(instance.bindClass)
  }

  onInput(event) {
    const input = event.target
    this.bindClass(input)
  }

  onInsert(event) {
    if (event.animationName === "inputInserted") {
      const target = event.target
      const input = target.querySelector("input")
      this.bindClass(input)
    }
  }

  bindClass(input) {
    if (input !== null) {
      if (input.value.length > 0 || input.hasAttribute("placeholder-sticky")) {
        if (!input.classList.contains("filled")) {
          input.classList.add("filled")
        }
      } else {
        input.classList.remove("filled")
      }
    }
  }
}
