if (process.env.production) {
  console.log("%cStop!", "font: 4em sans-serif; color: yellow; background-color: red;")
  console.log(
    "%cDeveloper tool is provided only for developers, please do not use it.",
    "font: 2em sans-serif; color: yellow; background-color: red;"
  )
  window.console.warn = function () {}
  window.console.log = function () {}
  window.console.info = function () {}
}

window.bowser = require("bowser")
window.isUnsupportedBrowser = function () {
  return bowser.isUnsupportedBrowser(
    { chrome: "63", firefox: "57", opera: "49", android: "53", safari: "12", samsungBrowser: "4" },
    true
  )
}
window.isMobile = function () {
  return (
    bowser.mobile ||
    // check for iPad 13+ (user agent looks like MacOS Desktop)
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1) ||
    false
  )
}

require("es6-object-assign").polyfill()
require("mutationobserver-shim")
require("intersection-observer")
require("./scripts")

import RxTime from "./vuejs/plugins/RxTime"
window.RxTime = new RxTime()

document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    if (
      (bowser.mobile || false) &&
      !window.__STATIC_PAGE &&
      typeof SupportCenter !== typeof undefined
    ) {
      SupportCenter.disable()
    }
  }
}
