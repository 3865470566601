import Tooltip from "./ui/tooltip"
import { findParent } from "./helpers/utils"
import Inputs from "./helpers/inputs"
import Velocity from "velocity-animate"
import Siema from "siema"
import SupportCenter from "./helpers/support-center"
window.SupportCenter = SupportCenter
import VueCookie from "vue-cookie"
import { link } from "glamor"
import Translate from "./helpers/translate"

document.addEventListener("DOMContentLoaded", function (event) {
  let elFooterPhone = document.querySelector("footer a.social.phone")
  if (elFooterPhone !== null) {
    new Tooltip(elFooterPhone)
  }

  Inputs.bind()

  const items = document.querySelectorAll(".results-list .show-more")
  if (items !== void 0) {
    items.forEach((el) =>
      el.addEventListener("click", function (e) {
        const target = this.nextElementSibling()
        Velocity(target, "slideDown", { duration: 300 })
        Velocity(target, "fadeIn", { duration: 300 })

        Velocity(this, "slideUp", { duration: 300 })
        Velocity(this, "fadeOut", { duration: 300 })
      })
    )
  }

  document.querySelectorAll(".post-change").forEach((el) =>
    el.addEventListener("click", function (e) {
      const form = findParent(this, "form")
      form.submit()
    })
  )

  if (!bowser.mobile) {
    document.querySelectorAll(".fixed-scroll").forEach((el) => {
      const height = el.offsetHeight
      const offset = el.offsetTop
      const blank = document.createElement("div")
      blank.style.height = height + "px"

      const f = () => {
        if (document.documentElement.scrollTop > offset) {
          if (!el.parentNode.contains(blank)) {
            el.parentNode.insertBefore(blank, el.nextSibling)
          }
          el.style.position = "fixed"
        } else {
          if (el.parentNode.contains(blank)) {
            el.parentNode.removeChild(blank)
          }
          el.style.position = "relative"
        }
      }

      document.addEventListener("scroll", f)
      f()
    })
  }

  document.querySelectorAll("[data-scroll-to]").forEach((el) =>
    el.addEventListener("click", function (e) {
      e.preventDefault()

      const scrollTo = this.getAttribute("data-scroll-to")
      if (document.querySelector(scrollTo).length == 0) return
      const scrollToElement = document.querySelector(scrollTo)

      Velocity(scrollToElement, "scroll", { duration: 500 })

      // const offset = document.querySelector(scrollTo).offsetTop

      // document.documentElement.scrollTop = offset
    })
  )

  document.querySelectorAll("[data-show-click]").forEach((el) =>
    el.addEventListener("click", function (e) {
      if (this.hasAttribute("data-show-click")) {
        const attr = this.getAttribute("data-show-click")
        this.innerText = attr
        this.removeAttribute("data-show-click")
      }
    })
  )

  document.addEventListener("click", (event) => {
    if (
      event.target !== document.querySelector(".top-bar .switcher span") &&
      event.target !== document.querySelector(".top-bar .switcher *") &&
      document.querySelector(".top-bar .switcher ul") !== null
    ) {
      document.querySelector(".top-bar .switcher ul").style.display = "none"
    }

    if (
      event.target !== document.querySelector("header .nav-wrapper .more a") &&
      event.target !== document.querySelector("header .nav-wrapper .more *") &&
      document.querySelector("header .nav-wrapper .more ul") !== null
    ) {
      document.querySelector("header .nav-wrapper .more ul").style.display = "none"
    }
  })

  document.querySelectorAll(".top-bar .switcher > span").forEach((el) =>
    el.addEventListener("click", function (e) {
      if (e.target === el) {
        e.preventDefault()

        let sub = this.parentNode.querySelector("ul")
        sub.style.display = getComputedStyle(sub).display == "none" ? "block" : "none"
      }
    })
  )

  document.querySelectorAll("header .nav-wrapper .more > a").forEach((el) =>
    el.addEventListener("click", function (e) {
      if (e.target === el) {
        e.preventDefault()

        let sub = this.parentNode.querySelector("ul")
        sub.style.display = getComputedStyle(sub).display == "none" ? "block" : "none"
      }
    })
  )

  document.querySelectorAll(".btn-side-nav").forEach((el) =>
    el.addEventListener("click", function (e) {
      e.stopPropagation()
      let el = this

      if (el.className.indexOf("active") === -1) {
        el.className = el.className + " active"
      } else {
        el.className = el.className.replace(/\s*active\s*/g, "")
      }

      let header = document.querySelector("header .wrapper-side-nav")
      header.style.display = getComputedStyle(header).display == "none" ? "block" : "none"
    })
  )

  document.querySelectorAll("section .scroll-down").forEach((el) =>
    el.addEventListener("click", (e) => {
      let target = e.target
      let parent = findParent(target, "section")
      let next = parent.nextElementSibling

      Velocity(next, "scroll", { duration: 500 })
    })
  )

  const siemaBindInterval = (instance, time) => {
    instance.interval = setInterval(() => {
      instance.next()
    }, time || 5000)
  }

  const siemaBindArrows = (instance) => {
    let arrowPrev = document.createElement("div")
    arrowPrev.className = "siema-arrow siema-prev"
    arrowPrev.onclick = () => instance.prev()
    let arrowNext = document.createElement("div")
    arrowNext.className = "siema-arrow siema-next"
    arrowNext.onclick = () => instance.next()

    instance.selector.insertBefore(arrowPrev, instance.selector.childNodes[0])
    instance.selector.appendChild(arrowNext)
  }

  const siemaBindDots = (instance) => {
    let dots = document.createElement("ul")
    dots.className = "siema-dots"

    let countItems = instance.innerElements.length
    let countDots = instance.perPage === 1 ? countItems : countItems - 1

    let li = document.createElement("li")
    for (var i = 0; i < countDots; i++) {
      let item = li.cloneNode()
      item.addEventListener(
        "click",
        function (i) {
          this.goTo(i)
        }.bind(instance, i)
      )
      dots.appendChild(item)
    }

    if (dots.childNodes.length > 0) {
      dots.childNodes[instance.currentSlide].className = "siema-active"
    }

    instance.selector.parentNode.appendChild(dots)
  }

  const siemaChangeDots = (instance) => {
    let dots = instance.selector.parentElement.querySelector("ul")
    if (dots !== null) {
      let activeDot = dots.querySelector(".siema-active")
      if (activeDot !== null) {
        activeDot.className = ""
      }

      dots.childNodes[instance.currentSlide].className = "siema-active"
    }
  }

  document.querySelectorAll(".home-page .header-slider").forEach((el) => {
    el.siema = new Siema({
      selector: el,
      loop: true,
      duration: 300,
      onInit: function () {
        siemaBindInterval(this, 10000)
      },
    })

    el.addEventListener("mouseenter", () => clearInterval(el.siema.interval))
    el.addEventListener("mouseleave", () => siemaBindInterval(el.siema, 10000))
  })

  document.querySelectorAll(".home-page .quotes").forEach((el) => {
    el.siema = new Siema({
      selector: el.querySelector(".items"),
      loop: false,
      duration: 300,
      onInit: function () {
        siemaBindArrows(this)
      },
    })
  })

  document.querySelectorAll(".home-page .blog-posts .posts").forEach((el) => {
    el.siema = new Siema({
      selector: el.querySelector(".items"),
      loop: false,
      duration: 300,
      onInit: function () {
        siemaBindDots(this)
      },
      onChange: function () {
        siemaChangeDots(this)
      },
      perPage: {
        800: 2,
        600: 1,
      },
    })
  })

  document.querySelectorAll(".hero-references").forEach((el) => {
    el.classList.remove("hidden")
    el.siema = new Siema({
      selector: el.querySelector(".brands"),
      loop: true,
      duration: 300,
      onInit: function () {
        siemaBindDots(this)
      },
      onChange: function () {
        siemaChangeDots(this)
      },
      perPage: {
        1200: 6,
        800: 5,
        600: 4,
        300: 3,
      },
    })
  })
  document.querySelectorAll(".product-references").forEach((el) => {
    el.siema = new Siema({
      selector: el.querySelector(".countries"),
      loop: true,
      duration: 300,
      onInit: function () {
        siemaBindDots(this)
      },
      onChange: function () {
        siemaChangeDots(this)
      },
      perPage: {
        1200: 5,
        800: 4,
        600: 3,
        300: 2,
      },
    })
  })

  // document.querySelectorAll(".tabs-container").forEach(el => {
  //   el.siema = new Siema({
  //     selector: el.querySelector(".tabs"),
  //     loop: false,
  //     duration: 300,
  //     perPage: {
  //       300: 3
  //     }
  //   })
  // })
  // // fix tab width for mobile
  // let teamTabs = document.querySelectorAll('.team-tab')
  // teamTabs.forEach(teamTab => {
  //   teamTab.closest('div').classList.add('auto-width')
  // })

  document.querySelectorAll(".references").forEach((el) => {
    el.siema = new Siema({
      selector: el.querySelector(".references-container"),
      loop: true,
      duration: 300,
      onInit: function () {
        siemaBindDots(this)
        siemaBindArrows(this)
      },
      onChange: function () {
        siemaChangeDots(this)
      },
      perPage: {
        300: 1,
      },
    })
  })

  document.querySelectorAll(".hero-features").forEach((el) => {
    el.siema = new Siema({
      selector: el.querySelector(".features"),
      loop: true,
      duration: 300,
      onInit: function () {
        siemaBindDots(this)
        siemaBindArrows(this)
      },
      onChange: function () {
        siemaChangeDots(this)
      },
      perPage: {
        300: 1,
      },
    })
  })

  document.querySelectorAll(".products-slider").forEach((el) => {
    el.siema = new Siema({
      selector: el.querySelector(".products"),
      loop: true,
      duration: 300,
      onInit: function () {
        siemaBindDots(this)
      },
      onChange: function () {
        siemaChangeDots(this)
      },
      perPage: {
        300: 1,
      },
    })
  })

  document.querySelectorAll(".organizations.logos-slider .logos").forEach((el) => {
    el.siema = new Siema({
      selector: el.querySelector(".items"),
      loop: true,
      duration: 300,
      onInit: function () {
        siemaBindInterval(this)
        siemaBindArrows(this)
      },
      perPage: {
        1400: 7,
        1000: 5,
        800: 3,
        480: 2,
      },
    })

    el.addEventListener("mouseenter", () => clearInterval(el.siema.interval))
    el.addEventListener("mouseleave", () => siemaBindInterval(el.siema))
  })

  // *** view switcher desktop <-> mobile ***

  const views = document.querySelectorAll(".view")
  let allViewsVisible = false

  views.forEach(toggleView)

  function toggleView(view) {
    allViewsVisible = false
    const switchersContainer = view.querySelector(".view-switchers")
    const images = view.querySelectorAll(".image")
    const switchers = view.querySelectorAll(".view-switcher")
    const switcherBg = view.querySelector(".view-switcher-bg")
    switchersContainer.addEventListener("click", function (e) {
      const clicked = e.target.closest("button")
      if (!clicked.classList.contains("view-switcher--active"))
        switcherBg.classList.toggle("view-switcher-bg--mobile")
      switchers.forEach((switcher) => switcher.classList.remove("view-switcher--active"))
      clicked.classList.add("view-switcher--active")
      images.forEach((image) => image.classList.remove("image--active"))
      view.querySelector(`.view-${clicked.dataset.tab}`).classList.add("image--active")
    })
  }

  // *** handle show more / less on details section ***

  const hiddableViews = document.querySelectorAll(".hiddable")
  const showButton = document.querySelector(".load-more")
  if (showButton) {
    const showButtonText = showButton.getElementsByTagName("span")
    showButtonText[0].textContent = Translate.get("common.show-more")

    showButton.addEventListener("click", function () {
      hiddableViews.forEach((hiddableView) => {
        if (hiddableView.classList.contains("hidden")) {
          hiddableView.classList.remove("hidden")
        } else {
          hiddableView.classList.add("hidden")
          showButton.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      })

      allViewsVisible = !allViewsVisible

      if (!allViewsVisible) {
        showButtonText[0].textContent = Translate.get("common.show-more")
      } else {
        showButtonText[0].textContent = Translate.get("common.show-less")
      }
    })
  }

  // *** handle switch tab in details and team section ***

  const tabSwitchers = document.querySelectorAll(".tab-switcher")

  if (tabSwitchers) {
    tabSwitchers.forEach((tabSwitcher) => {
      const tabs = tabSwitcher.querySelectorAll(".tab")
      const tabsContainer = tabSwitcher.querySelector(".tabs.tabs-static")
      const tabsContent = tabSwitcher.querySelectorAll(".tabs-content")

      tabsContainer.addEventListener("click", function (e) {
        const clicked = e.target.closest("button")
        tabs.forEach((tab) => tab.classList.remove("tab--active"))
        clicked.classList.add("tab--active")
        tabsContent.forEach((tabC) => tabC.classList.remove("tabs-content--active"))
        tabSwitcher
          .querySelector(`.tabs-content-${clicked.dataset.tab}`)
          .classList.add("tabs-content--active")
      })
    })
  }

  // *** handle hiddable navbar ***
  let navbar = document.getElementById("header-app")
  if (navbar && navbar.classList.contains("no-hide") === false) {
    let prevScrollPos = window.scrollY
    function hiddableNavbar() {
      let currentScrollPos = window.scrollY
      if (prevScrollPos > currentScrollPos) {
        navbar.style.top = "0"
        if (currentScrollPos > 150) {
          navbar.classList.add("small-navbar")
        } else {
          navbar.classList.remove("small-navbar")
        }
      } else if (window.scrollY > 150) {
        navbar.style.top = "-150px"
      }
      prevScrollPos = currentScrollPos
    }
    document.addEventListener("scroll", hiddableNavbar)
  }

  // *** handle custom terms accordions ***

  var locale = document.getElementsByTagName("html")[0].getAttribute("lang")

  const terms = document.querySelectorAll(".term")
  if (terms) {
    terms.forEach(toggleVisibility)

    function toggleVisibility(term) {
      let termButton = term.querySelector(".term-button")
      termButton.addEventListener("click", function () {
        let termContent = term.querySelector(".term-content")
        let termButtonText = termButton.querySelector("span")
        let arrow = termButton.querySelector("img")
        if (termContent.classList.contains("active")) {
          termContent.classList.remove("active")
          arrow.classList.remove("active")
          termButtonText.textContent = Translate.get("common.show-more")
          term.scrollIntoView({ behavior: "smooth", block: "start" })
        } else {
          termContent.classList.add("active")
          arrow.classList.add("active")
          termButtonText.textContent = Translate.get("common.show-less")
        }
      })
    }
  }

  let guestMenu = document.querySelector(".guest-menu")
  if (guestMenu && screen.width < 1024) {
    guestMenu.style.height = window.innerHeight + "px"
  }

  // *** border observer for details sections ***

  let borderObserver = new IntersectionObserver(
    (borders, observer) => {
      borders.forEach((border) => {
        if (border.isIntersecting) {
          border.target.classList.add("animate-color")
          observer.unobserve(border.target)
        }
      })
    },
    { rootMargin: "0px 0px -75% 0px" }
  )
  document.querySelectorAll(".animated-border").forEach((el) => {
    borderObserver.observe(el)
  })

  // *** section observers ***

  let sectionObserver = new IntersectionObserver(
    (sections, observer) => {
      sections.forEach((section) => {
        if (section.isIntersecting) {
          section.target.classList.remove("translated", "translated-x", "-translated-x")
          section.target.classList.add("move-in")
          observer.unobserve(section.target)
        }
      })
    },
    { rootMargin: "0px 0px -11% 0px" }
  )
  document.querySelectorAll(".observed").forEach((el) => {
    sectionObserver.observe(el)
  })
})
